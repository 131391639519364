import debounce from "lodash/debounce";
import {useCallback} from "react";
import {useEffect, useRef} from "react";

function useCallbackWithDebounce(callback: Function, delay: number = 700) {
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(
        debounce((...args) => callbackRef.current(...args), delay),
        [delay]
    );
}

export default useCallbackWithDebounce;
