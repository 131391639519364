import React from "react";
import ReactDOMServer from 'react-dom/server';
import styles from "./TicketEditHistoryList.module.scss"
import parse from 'html-react-parser';
import moment from "moment";
import { groupBy } from "lodash";
import { ReactComponent as IconCalendar } from "../../assets/icons/calendar.svg"
import { ReactComponent as IconPeople } from "../../assets/icons/people.svg"
import { ReactComponent as IconDot } from "../../assets/icons/dot.svg"
import { ReactComponent as IconLock } from "../../assets/icons/lock.svg"
import { ReactComponent as IconVacasaLogo } from "../../assets/icons/vacasa-logo.svg"
import { ReactComponent as IconKey } from "../../assets/icons/key.svg"
import Moment from "react-moment";
import { HistoryFilterOptionEnum, TaggedUsernameRegex } from "appConstants";
import { TicketHistoryEntity } from "entities/TicketHistory";
import DOMPurify from "dompurify";
import { ConnectUserEntity } from "entities/ConnectUser";
import { slugifyUsername } from "utils/helpers";
import type { OwnerV1Attributes, TicketCommentV1Attributes, TicketHistoryV1Attributes, UserConnectAttributes } from "typing/dto";

interface ITicketEditHistoryListProps {
    history: TicketHistoryV1Attributes[],
    comments: TicketCommentV1Attributes[],
    users: UserConnectAttributes[],
    owners: OwnerV1Attributes[]
}

type TimelineAttributes = TicketCommentV1Attributes & TicketHistoryV1Attributes;

const timeFormat = "h:mma";

export const TicketEditHistoryList: React.FC<ITicketEditHistoryListProps> = ({history, comments, users, owners}: ITicketEditHistoryListProps) => {
    const dateGroupFormat = "YYYY-MM-DD";
    
    const sortedDataByTime = [
        ...(history as TimelineAttributes[]), 
        ...(comments as TimelineAttributes[])
        ]
        .sort((a, b) => (
            moment(a?.timestamp || new TicketHistoryEntity(a).creationDate) < 
            moment(b?.timestamp || new TicketHistoryEntity(b).creationDate) ? 
            1 : -1
        ));
    
    const itemGroups = groupBy(sortedDataByTime
        .map((item) => ({
            ...item,
            dateGroup: item?.created_at ?
                moment(new TicketHistoryEntity(item).creationDate).format(dateGroupFormat) : 
                moment(item.timestamp).format(dateGroupFormat),
        })), "dateGroup");
    
    const sortedItemsByDate = Object.keys(itemGroups)
        .sort((a:string, b:string) => (a > b ? -1 : 1))
        .reduce((a:any, c:any) => {
            const prev = {...a};
            prev[c] = itemGroups[c];
            return prev;
        }, {});
    
    return ( 
        <section data-testid="history-list" className={styles.content}>
            {Object.keys(sortedItemsByDate).map((dateGroup: string) => (
                <div key={`${dateGroup}-container`}>
                    <CalendarItem key={`${dateGroup}-calendar`} date={dateGroup} />
                    {sortedItemsByDate[dateGroup]?.map((item: TimelineAttributes) => (
                      <div key={`${item.id}-container`}>
                        {item?.note ?
                            <CommentItem key={`${item.id}-comment`} comment={item} users={users} owners={owners}/> :
                            <StandardItem key={`${item.id}-standard`} history={item} users={users} />
                        }
                      </div>
                    ))}
                </div>
            ))}
        </section>
    );
}

interface ICalendarItemProps {
    date: string,
    dateFormat?: string
}
export const CalendarItem: React.FC<ICalendarItemProps> = ({date, dateFormat="LL"}: ICalendarItemProps) => {
    const isToday = moment(date).isSame(new Date(), "day");
    const isYesterday = moment(date).isSame(moment().subtract(1, 'days'), "day");
    return (
        <BaseItem icon={<IconCalendar />}>
            <h4><Moment format={dateFormat}>{date}</Moment>{isToday && <> - Today</>}{isYesterday && <> - Yesterday</>}</h4>
        </BaseItem>
    )
}

interface IHistoryProps {
    history: TicketHistoryV1Attributes,
    users: UserConnectAttributes[]
}
const StandardItem: React.FC<IHistoryProps> = ({history, users}: IHistoryProps) => {
    const {created_by} = history;
    const ticketHistoryEntity = new TicketHistoryEntity(history);
    const author = users.find(user => user.id === created_by);
    const assignee = ticketHistoryEntity.assignee ? users.find(user => user.id === ticketHistoryEntity.assignee) : null;
    const creationDate = ticketHistoryEntity.creationDate;
    return (
        <>
            <BaseItem icon={<IconDot className={styles.dot}/>}>
                {assignee && !ticketHistoryEntity.text ?
                    <p>Assigned to {new ConnectUserEntity(assignee).fullName}</p> :
                    <p>{ticketHistoryEntity.text}</p>
                }
                <p> 
                    {creationDate && <Moment format={timeFormat}>{creationDate}</Moment>}
                    {author && <> by {new ConnectUserEntity(author).fullName}</>}
                </p>
            </BaseItem>
        </>
    )
}

interface ICommentProps {
    comment: TicketCommentV1Attributes,
    users: UserConnectAttributes[],
    owners: OwnerV1Attributes[],
}
const CommentItem: React.FC<ICommentProps> = ({comment, users, owners}: ICommentProps) => {
    const {external, note, timestamp, user_id, assigned_to} = comment;
    const isInternal = external === 0;
    const assignee = !!assigned_to ? users.find(user => user.id === assigned_to) : null
    const createdBy = users.find(user => user.id === user_id);
    const isOwner = !!createdBy ? !!owners.find(owner => owner.id === createdBy.id) : null;

    const parseComment = (text: string): string => {
        const parts = text.split(new RegExp(TaggedUsernameRegex.AT_INCLUDED, "gu")); // Matches @username patterns
        return parts.map((part: string, index: number) => {
            if (part.startsWith('@')) {
                // Generate the username without the '@' symbol
                const username = slugifyUsername(part.slice(1));
                const taggedUser = users.find((user) => slugifyUsername(user.username).toLocaleLowerCase() === username.toLocaleLowerCase())
                // Create a link to the user's profile
                return taggedUser ? ReactDOMServer.renderToStaticMarkup(
                    <a
                        key={index}
                        href={`/ticket/search?filter[follower_id]=${taggedUser?.id}`} // Replace with actual profile URL
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#0066cc', textDecoration: 'underline' }}
                    >
                        {part}
                    </a>
                ) : part;
            }
            return part;
        }).join('');
    };
    const safeNote = parse(DOMPurify.sanitize(parseComment(note)));
    return (
        <BaseItem icon={isInternal ? <IconLock /> : <IconPeople />}>
            <p>
                {assignee ?
                    `Reassigned to ${new ConnectUserEntity(assignee).fullName}` :
                    <>
                    {isInternal ? HistoryFilterOptionEnum.STAFF_ONLY : HistoryFilterOptionEnum.STAFF_OWNER} 
                    </>
                } - <Moment format={timeFormat}>{timestamp}</Moment>
                {createdBy && <> by {new ConnectUserEntity(createdBy).fullName}</>}
            </p>
            {  isInternal ?
                <div>{safeNote}</div> :
                <div className={styles["comment-box"]}>
                    <span className={styles["comment-type"]}>
                        { isOwner ? 
                            <><IconKey /> Owner</> :
                            <><IconVacasaLogo /> Staff</> 
                        } 
                    </span>
                    <div>{safeNote}</div>
                </div>
            }
        </BaseItem>
    )
}

interface IBaseItem {
    visible?: boolean,
    children: any,
    icon: React.ReactNode
}
const BaseItem: React.FC<IBaseItem> = ({children, icon, visible = true}: IBaseItem) => {
    return (
        <>
            { visible &&
                <div className={styles.item}>
                    <div className={styles.left}>
                        <div className={styles["icon-wrap"]}>
                            {icon}
                        </div>
                    </div>
                    <div className={styles.right}>
                        {children}
                    </div>
                </div>
            }
        </>
    )
}
