import React from "react";
import styles from "./TicketSearchTable.module.scss";
import { LIST_DATE_FORMAT, SearchOrderKeysEnum } from "appConstants";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";
import { TableSortLabel } from 'components/table-sort-label/TableSortLabel';
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/store";
import useURLParams from "hooks/useURLParams";
import Moment from "react-moment";
import { setPage, setReload } from "store/ticketSearchSlice";
import type { RootState } from "store/store";
import type { BaseSyntheticEvent } from "react";
import type { MetaPagination } from "typing/request";
import type { UserConnectAttributes, UnitV2Attributes } from "typing/dto";
import { goToReservation, goToTicket } from "utils/helpers";
import { TicketSearchRow } from "typing";
import { AdminService } from "services/AdminService";
import isNil from "lodash/isNil"
import { ExtraTextTooltip } from "components/extra-text-tooltip/ExtraTextTooltip";

interface TicketSearchTableProps {
}

export const TicketSearchTable: React.FC<TicketSearchTableProps> = () => {
    const dispatch = useAppDispatch();
    const titleMaxLength = 50;

    const { setURLParam: setPageLimitParam } = useURLParams('page[limit]');
    const { setURLParam: setPageNumberParam } = useURLParams('page[number]');
    const createdAtFormat = LIST_DATE_FORMAT;
    const visibilityMap = new Map<string, string>([
        ["owner", "Staff & Owner"],
        ["internal", "Staff Only"]
    ]);

    const ticketList = useSelector(
        (state: RootState) => state.ticketSearch.tickets
    );
    const pagination: MetaPagination | null = useSelector(
        (state: RootState) => state.ticketSearch.pagination
    );
    const assignedUsers: UserConnectAttributes[] = useSelector(
        (state: RootState) => state.ticketSearch.users
    );
    const units: UnitV2Attributes[] = useSelector(
        (state: RootState) => state.ticketSearch.units
    );
    const getAssignedName = (userId: number) => assignedUsers
        .filter((user:UserConnectAttributes) => Number(user.id) === Number(userId))
        .map((user:UserConnectAttributes) => `${user.first_name} ${user.last_name}`)
        .join();
    const getUnitCode = (unitId: number) => units
        .filter((unit:UnitV2Attributes) => Number(unit.legacy_unit_id) === Number(unitId))
        .map((unit:UnitV2Attributes) => `${unit.unit_code}`)
        .join();
    const rows: TicketSearchRow[] = ticketList ?
        ticketList.map(ticket => {
            const {attributes} = ticket;
            return {
                id: ticket.id,
                visible: visibilityMap.get(attributes.visibility),
                title: attributes.title,
                unitCode: getUnitCode(attributes.unit_id),
                status: attributes.display_status,
                createdAt: attributes.creation_date,
                commentCount: attributes.comments_count,
                assignedTo: getAssignedName(attributes.assigned_to),
                createdBy: getAssignedName(attributes.created_by),
                ownerCreated: attributes.requested_by === 1 ? 'Y':'N',
                caseId: attributes.sf_case_id,
                caseNumber: attributes.sf_case_number,
                reservationId: attributes.reservation_id
            } as unknown as TicketSearchRow;
        }): [];

    const rowsPerPageOptions = [10, 25, 100];
    const page = pagination?.current_page ? pagination?.current_page - 1 : 0;
    const rowsPerPage = pagination?.per_page ? Number(pagination.per_page) : 20;
    const count = pagination?.total ?? 0;

    const handleChangePage = (_event: any, page: number) => {
        setPageLimitParam(String(pagination?.per_page || 10))
        setPageNumberParam(String(page + 1))
        dispatch(setPage({
            limit: Number(pagination?.per_page),
            number: page + 1
        }));
        dispatch(setReload());
    };
    
    const handleChangeRowsPerPage = (event: BaseSyntheticEvent) => {
        const pageLimit: string = event.target.value;
        setPageLimitParam(pageLimit)
        dispatch(setPage({
            limit: pageLimit,
            number: pagination?.current_page
        }));
        dispatch(setReload());
    };

    return ( 
        <section className={styles.content}>
            <TableContainer>
                <Table>
                    <TableHead data-testid="ticket-search-table-header">
                        <TableRow key="header">
                            <TableCell style={{ width: '6%' }} align="center">Visible</TableCell>
                            <TableCell style={{ width: '15%' }} align="left">Title</TableCell>
                            <TableCell style={{ width: '11%' }} align="left">
                                <TableSortLabel sortProperty={SearchOrderKeysEnum.UNIT_CODE}>Unit Code</TableSortLabel>
                            </TableCell>
                            <TableCell style={{ width: '10%' }} align="left">
                                <TableSortLabel sortProperty={SearchOrderKeysEnum.RESERVATION_ID}>Reservation</TableSortLabel>
                            </TableCell>
                            <TableCell style={{ width: '8%' }} align="left">
                                <TableSortLabel sortProperty={SearchOrderKeysEnum.STATUS}>Status</TableSortLabel>
                            </TableCell>
                            <TableCell style={{ width: '10%', position: 'relative'}} align="left">
                                <TableSortLabel sortProperty={SearchOrderKeysEnum.CREATION_DATE}>Created at</TableSortLabel>
                            </TableCell>
                            <TableCell style={{ width: '4%' }} align="center">Comment Count</TableCell>
                            <TableCell style={{ width: '10%' }} align="left">Assigned To</TableCell>
                            <TableCell style={{ width: '10%' }} align="left">Created By</TableCell>
                            <TableCell style={{ width: '5%' }} align="center">Owner Created</TableCell>
                            <TableCell style={{ width: '5%' }} align="left">Case #</TableCell>
                            <TableCell style={{ width: '6%' }} align="center">Ticket ID</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.length ? (rows.map((row: any) => (
                        <TableRow key={row.id}>
                            <TableCell style={{ width: '6%' }} align="center" onClick={() => {goToTicket(row)}}>{row.visible}</TableCell>
                            <TableCell style={{ width: '15%' }} onClick={() => {goToTicket(row)}}>
                                <ExtraTextTooltip text={row.title} maxLength={titleMaxLength}/>
                            </TableCell>
                            <TableCell style={{ width: '11%' }} onClick={() => {goToTicket(row)}}>{row.unitCode}</TableCell>
                            {isNil(row.reservationId) ? (
                                <TableCell style={{ width: '10%' }} onClick={() => goToTicket(row)} role="cell"/>
                            ) : (
                                <TableCell style={{ width: '10%' }} onClick={() => goToReservation(row)} className={styles.link} role="cell">
                                    <a
                                        href={AdminService.getReservationUrl(row.reservationId)}
                                        target="_blank"
                                        data-testid="reservation-id"
                                        rel="noreferrer">{row.reservationId}
                                    </a>
                                </TableCell>
                            )}
                            <TableCell style={{ width: '8%' }} onClick={() => {goToTicket(row)}}>{row.status}</TableCell>
                            <TableCell style={{ width: '10%' }} onClick={() => {goToTicket(row)}}>
                                <Moment className="uppercase" format={createdAtFormat}>{row.createdAt}</Moment>
                            </TableCell>
                            <TableCell style={{ width: '4%' }} align="center" onClick={() => {goToTicket(row)}}>{row.commentCount}</TableCell>
                            <TableCell style={{ width: '10%' }} onClick={() => {goToTicket(row)}}>{row.assignedTo}</TableCell>
                            <TableCell style={{ width: '10%' }} onClick={() => {goToTicket(row)}}>{row.createdBy}</TableCell>
                            <TableCell style={{ width: '5%' }} align="center" onClick={() => {goToTicket(row)}}>{row.ownerCreated}</TableCell>
                            <TableCell style={{ width: '5%' }} onClick={() => {goToTicket(row)}}>{row.caseNumber}</TableCell>
                            <TableCell style={{ width: '6%' }} align="center" onClick={() => {goToTicket(row)}}>{row.id}</TableCell>
                        </TableRow>
                    ))) : <TableRow>
                            <TableCell style={{ width: '100%' }} colSpan={10} align="center">
                                No records found
                            </TableCell>
                        </TableRow>
                    }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </section>
    );
}
