import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {GenAIService} from "services/GenAIService";
import {OwnerSummaryAttributes} from "typing/dto";

// Service Singletons
const genAIService = GenAIService.getInstance();

export const fetchSummary = createAsyncThunk("ticketOwnerSummary/fetchSummary", async (unitId: number, {rejectWithValue}) => {
    try {
        const response = await genAIService.getOwnerSummaryByUnitId(unitId);
        return response;
    } catch (e) {
        // @ts-ignore
        throw rejectWithValue(e.response.data);
    }
});

export interface ITicketOwnerSummaryState {
    error: string;
    isLoadingFetch: boolean;
    summary: OwnerSummaryAttributes | null;
}

export const initialState: ITicketOwnerSummaryState = {
    isLoadingFetch: false,
    summary: null,
    error: "",
};

const ticketOwnerSummarySlice = createSlice({
    name: "ticketOwnerSummary",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSummary.pending, (state: ITicketOwnerSummaryState) => {
                state.isLoadingFetch = true;
                state.summary = null;
                state.error = "";
            })
            .addCase(fetchSummary.fulfilled, (state: ITicketOwnerSummaryState, {payload}) => {
                state.isLoadingFetch = false;
                state.summary = payload as OwnerSummaryAttributes;
            })
            .addCase(fetchSummary.rejected, (state, action) => {
                state.isLoadingFetch = false;
                state.error = // @ts-ignore
                    action?.payload?.error || "There was an error while obtaining the summary, please retry to see if that fixes the issue.";
            });
    },
});

export default ticketOwnerSummarySlice.reducer;
