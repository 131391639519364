import {DEFAULT_FILTER_VALUES, ListFilterEnum, TicketStatusCodeEnum} from "appConstants";
import moment from "moment";
import type {UnitV2Attributes, UserConnectAttributes} from "typing/dto";
import type {FilterOption, SearchFilterOptions, SearchSelectedFilterValues, UserFilterOptions} from "typing/request";

/**
 * Transforms an array of user objects into an array of filter items.
 *
 * Transform `UserConnectAttributes` object to `FilterOption`
 *
 * @param users - An array of user objects to transform.
 * @returns A new array of filter items with `FilterOption` format.
 */
export const transformUserToFilterItem = (users: UserConnectAttributes[]) => {
    return users.map(({first_name, last_name, id}) => ({
        name: `${first_name} ${last_name}`,
        id,
    }));
};

export const USER_FILTER_IDS = [ListFilterEnum.ASSIGNED_TO, ListFilterEnum.FOLLOWER_ID, ListFilterEnum.MANAGER_ID, ListFilterEnum.CREATED_BY];

/**
 * Extracts user IDs from query params.
 *
 * There are different query params that involves user IDs (`USER_FILTER_IDS`)
 *
 * e.g. `?filter[manager_id]=1,2&filter[assigned_to]=2,3&filter[follower_id]` to
 *      `"1,2,3,4,5"`
 *
 * @param filterQueryParams
 * @returns A comma separated user IDs. e.g. `"1,2,3,4"`
 */
export const getUserIdsFromQueryParams = (filterQueryParams: SearchFilterOptions): string => {
    return Object.entries(filterQueryParams)
        .filter(([key]) => USER_FILTER_IDS.includes(key as ListFilterEnum))
        .map(([, value]) => value)
        .join(",");
};

/**
 *  Separates the user list in categories
 *
 * e.g. `[{user1}, {user2}, {user4}]` to
 * -> `follower = [{user1}]` | `manager = [{user2}]` | `assignee = [{user3}]`
 *
 * @param {FilterOption[]} usersFilterItems - A `FilterOption` array of users
 * @param {string} filterValue - Value used to filter
 * @returns An array of FilterOption with filtered options.
 */
export const filterUsersById = (usersFilterItems: FilterOption[], filterValue?: string) => {
    return usersFilterItems.filter((user) => filterValue?.split(",").includes(String(user.id)));
};

/**
 * Get user query param in a compatible format
 *
 * e.g.
 *     from: `?filter[manager_id]=1,2&filter[assigned_to]=2,3&filter[follower_id]`
 *     to: `{ MANAGER_ID: [{ user1 }, {user2}], ASSIGNED_TO: [...] }`
 *
 * @param {SearchFilterOptions} filterQueryParams - The query parameters with user IDs to filter.
 * @param {UserConnectAttributes[]} [users] - Optional array of user attributes for filtering.
 * @returns An object with `FilterOption` items.
 */
export const getSelectedUsers = (filterQueryParams: SearchFilterOptions, users?: UserConnectAttributes[]): SearchSelectedFilterValues | {} => {
    const userIds = getUserIdsFromQueryParams(filterQueryParams);

    if (!userIds) return {};

    const usersFilterItems = users ? transformUserToFilterItem(users) : [];
    const managers = filterUsersById(usersFilterItems, filterQueryParams[ListFilterEnum.MANAGER_ID]);

    return {
        [ListFilterEnum.ASSIGNED_TO]: filterUsersById(usersFilterItems, filterQueryParams[ListFilterEnum.ASSIGNED_TO]),
        [ListFilterEnum.FOLLOWER_ID]: filterUsersById(usersFilterItems, filterQueryParams[ListFilterEnum.FOLLOWER_ID]),
        [ListFilterEnum.CREATED_BY]: filterUsersById(usersFilterItems, filterQueryParams[ListFilterEnum.CREATED_BY]),
        ...(managers.length && {[ListFilterEnum.MANAGER_ID]: managers}),
    };
};

// Units

/**
 * Transforms an array of unit objects into an array of FilterOption items.
 *
 * Transform `UnitV2Attributes` object to `FilterOption`
 *
 * @param units - An array of unit objects to transform.
 * @returns A new array of filter items with `FilterOption` format.
 */
export const transformUnitToFilterItem = (units: UnitV2Attributes[]) => {
    return units.map(({unit_code, legacy_unit_id}) => ({
        name: unit_code,
        id: legacy_unit_id,
    }));
};

/**
 * Get unit query param in a compatible format
 *
 * e.g.
 *     from: `?filter[unit_id]: 1,2`
 *     to: `{ UNIT_ID: [{ unit1 }, {unit2}] }`
 *
 * @param {SearchFilterOptions} filterQueryParams - The query parameters with unit IDs.
 * @param {UnitV2Attributes[]} [units] - Optional array of units attributes.
 * @returns An object with `FilterOption` items.
 */
export const getSelectedUnits = (filterQueryParams: SearchFilterOptions, units?: UnitV2Attributes[]): {unitId: FilterOption[]} | {} => {
    if (!filterQueryParams[ListFilterEnum.UNIT_ID] || !units) return {};

    return {[ListFilterEnum.UNIT_ID]: transformUnitToFilterItem(units)};
};

// Creation date range

/**
 * Get date range query param in a compatible format
 *
 * e.g.
 *     from: `?filter[creation_date_gte]=2024-11-13&filter[creation_date_lte]=2024-11-26`
 *     to: `{ START_DATE: [{ startDate }], END_DATE: [{ endDate }] }`
 *
 * @param {SearchFilterOptions} filterQueryParams - The query parameters with date range.
 * @returns An object with `FilterOption` items.
 */
export const getSelectedDates = (filterQueryParams: SearchFilterOptions) => {
    let [startDate, endDate] = [DEFAULT_FILTER_VALUES[ListFilterEnum.START_DATE]?.id, DEFAULT_FILTER_VALUES[ListFilterEnum.END_DATE]?.id];

    if (filterQueryParams[ListFilterEnum.START_DATE]) {
        startDate = moment(filterQueryParams[ListFilterEnum.START_DATE]).format("YYYY-MM-DD");
    }

    if (filterQueryParams[ListFilterEnum.END_DATE]) {
        endDate = moment(filterQueryParams[ListFilterEnum.END_DATE]).format("YYYY-MM-DD");
    }

    return {
        [ListFilterEnum.START_DATE]: {
            id: startDate,
            name: ListFilterEnum.START_DATE,
        },
        [ListFilterEnum.END_DATE]: {
            id: endDate,
            name: ListFilterEnum.END_DATE,
        },
    };
};

// Status

/**
 * Coverts filter[status] query param to categorized object
 *
 * e.g.
 *     from: `?filter[status]: 1`
 *     to: `{ STATUS: [{ status1 }] }`
 *
 * @param {SearchFilterOptions} filterQueryParams - The query parameters with `status` value.
 * @param {FilterOption[]} statusOptions - A list of all status options available.
 * @returns An object with `FilterOption` items.
 */
export const getSelectedStatus = (filterQueryParams: SearchFilterOptions, statusOptions: FilterOption[]) => {
    const statusFilter = {[ListFilterEnum.STATUS]: DEFAULT_FILTER_VALUES[ListFilterEnum.STATUS]};

    if (!(ListFilterEnum.STATUS in filterQueryParams)) return statusFilter;
    if (!filterQueryParams[ListFilterEnum.STATUS]) return {};

    const selectedStatusIds: Number[] = filterQueryParams[ListFilterEnum.STATUS].split(",").map((id: string) => Number(id));

    statusFilter[ListFilterEnum.STATUS] = statusOptions.filter((option) => selectedStatusIds.includes(Number(option.id)));

    return statusFilter;
};

/**
 * Coverts filter[overdue] query param to categorized object
 *
 * `filter[overdue]` is equal to `filter[status_lte]=8&filter[completion_date_lte]=2024-11-26`
 * it takes `filter[overdue]=true` and convert it to
 * `{ STATUS_LTE: {status_lte}, COMPLETION_DATE_LTE: {completion_date_lte} }`
 *
 * @param {SearchFilterOptions} filterQueryParams - The query parameters with `overdue` value.
 * @returns An object with `FilterOption` items.
 */
export const getSelectedOverdue = (filterQueryParams: SearchFilterOptions) => {
    let result: any = {};

    if (filterQueryParams[ListFilterEnum.OVERDUE] === "true") {
        result = {
            [ListFilterEnum.STATUS_LTE]: {
                id: String(TicketStatusCodeEnum.RESOLVED_COMPLETED),
                name: ListFilterEnum.STATUS_LTE,
            },
            [ListFilterEnum.COMPLETION_DATE_LTE]: {
                id: moment().format("YYYY-MM-DD"),
                name: ListFilterEnum.COMPLETION_DATE_LTE,
            },
        };
    }
    return result;
};

/**
 * Converts a user object into a filter option.
 *
 * @param {UserConnectAttributes} user - The user object to convert.
 * @returns {FilterOption} The filter option containing the user's full name and ID.
 */
export const userToFilterOption = ({id, first_name, last_name}: UserConnectAttributes): FilterOption => {
    return {name: `${first_name} ${last_name}`, id};
};

/**
 * Generates a default user filter options object based on the current user.
 *
 * It should be use to generates default values of:
 * - Manager filter
 * - Assignee filter
 * - Follower filter
 * @param {SearchFilterOptions} filterQueryParams - The query parameters with `manager_id` value.
 * @param {UserConnectAttributes | null} currentUser - The current user object or `null` if no user is set.
 * @returns {UserFilterOptions} An object containing default user filter options.
 */
export const getDefaultUsers = (filterQueryParams: SearchFilterOptions, currentUser: UserConnectAttributes | null) => {
    const defaultUsers: UserFilterOptions = {};

    if (ListFilterEnum.MANAGER_ID in filterQueryParams) return defaultUsers;
    if (currentUser) defaultUsers[ListFilterEnum.MANAGER_ID] = [userToFilterOption(currentUser)];

    return defaultUsers;
};
