import {ImplicitFlowService} from "./ImplicitFlowService";
import {env} from "core/env";
import isEmpty from "lodash/isEmpty";
import qs from "qs";
import {ConnectUnitAttributes, UserConnectAttributes} from "typing/dto";
import {ConnectServiceFilter, DataType, GetResponse, PageQueryParams} from "typing/request";

export class ConnectService extends ImplicitFlowService {
    private static baseUrl = env.REACT_APP_CONNECT_SERVICE_URL;
    private static service: ConnectService;
    private _currentUser: UserConnectAttributes | null;

    constructor() {
        super(ConnectService.baseUrl);
        this._currentUser = null;
    }

    /**
     * Get Singleton Instance
     */
    public static getInstance(): ConnectService {
        if (!ConnectService.service) ConnectService.service = new ConnectService();
        return ConnectService.service;
    }

    /**
     * Get Unit Entity based on it's id
     */
    public async getConnectUnitByLegacyId(legacyUnitId: number): Promise<ConnectUnitAttributes | null> {
        try {
            const axios = await this.axiosInstance();
            const {data: response} = await axios.get(`${this.apiUrl}/units/${legacyUnitId}`);

            // early return if none
            if (!response?.data) return null;

            // append id to attributes for easier handling
            response.data.attributes.id = response.data.id;
            return response?.data;
        } catch (e) {
            if (!(e instanceof Error)) throw e;
            this.logger.error("getConnectUnitByLegacyId error", e);
            throw e;
        }
    }

    public async getUsersById(ids: string): Promise<UserConnectAttributes[]> {
        const users = await this.getUsers({id: ids});
        return users.map((user: DataType<UserConnectAttributes>) => ({
            ...user.attributes,
            id: user.id,
        }));
    }

    /**
     * Get Logins/Users
     **/
    public async getUsers(filter?: ConnectServiceFilter, page?: PageQueryParams): Promise<DataType<UserConnectAttributes>[]> {
        try {
            const axios = await this.axiosInstance();
            const params = {
                page,
                filter,
            };
            const {data: response} = await axios.get(`${this.apiUrl}/logins`, {
                params,
                paramsSerializer: (params) => {
                    return qs.stringify(params, {arrayFormat: "brackets"});
                },
            });
            return (
                response?.data.map((user: DataType<UserConnectAttributes>) => ({
                    ...user,
                    attributes: {
                        ...user.attributes,
                        id: Number(user.id),
                    },
                })) || []
            );
        } catch (e) {
            if (!(e instanceof Error)) throw e;
            this.logger.error("getUsers", {}, e);
            throw e;
        }
    }

    /**
     * Get single user by UserID
     * @param userId
     * @param failSilent will skip throwing an exception if interface fails
     */
    public async getUserById(userId: number, failSilent = false): Promise<GetResponse<UserConnectAttributes> | null> {
        try {
            const axios = await this.axiosInstance();
            const {data: response} = await axios.get(`${this.apiUrl}/logins/${userId}`);
            response.data.attributes.id = parseInt(response.data.id);
            return response;
        } catch (e) {
            if (failSilent) return null;
            if (!(e instanceof Error)) throw e;
            this.logger.error("getUserById", {}, e);
            throw e;
        }
    }

    /**
     * Sets the logged user ID if there is none.
     * @returns
     */
    public async setCurrentUser(): Promise<void> {
        try {
            if (this.currentUser) return;
            const email = ConnectService.getAuthenticatedUser()?.email;
            const users = email ? await this.getUsers({email}) : [];
            this._currentUser = isEmpty(users) ? null : {...users[0].attributes, id: Number(users[0].id)};
        } catch (e) {
            if (e instanceof Error) ConnectService.service.logger.error("ConnectService.setCurrentUserId", {}, e);
            throw e;
        }
    }

    public get currentUser(): UserConnectAttributes | null {
        return this._currentUser;
    }

    public get currentUserId(): number {
        return this._currentUser ? Number(this._currentUser.id) : 0;
    }
}
