import React, {useState} from "react";
import styles from "./DatePickerFilter.module.scss";
import { FilterOption } from "typing/request";
import useURLParams from 'hooks/useURLParams';
import { DatePicker } from "@vacasa/react-components-lib";
import classnames from "classnames";
import moment from "moment";
import useCallbackWithDebounce from "hooks/useCallbackWithDebounce";
import { enqueueError } from "utils/message";
import { EVENT_LOG_DATA } from "appConstants";

interface DatePickerFilterProps {
    disable?: boolean;
    id?: string;
    onUpdate: (selectedList: FilterOption, filterId: string) => void;
    placeholder?: string;
    title?: string;
    date: Date
    className?: string;
    urlParams?: boolean;
    dateFormat?: string;
    minDate?: Date;
    maxDate?: Date;
}

export const DatePickerFilter: React.FC<DatePickerFilterProps> = ({
    disable,
    id = 'filter-id',
    onUpdate,
    placeholder = 'Pick a date',
    title,
    date,
    className,
    urlParams = true,
    dateFormat = "MM/dd/yyyy",
    minDate,
    maxDate,
}: DatePickerFilterProps) => {
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const { setURLParam } = useURLParams(`filter[${id}]`);
    const paramDateFormat = "YYYY-MM-DD";

    const isValid = (date: Date): boolean => {
        if (!moment(date).isValid()) {
            enqueueError({
                logInfo: EVENT_LOG_DATA.FILTER_DATE_ADD,
                error: new Error("The date is invalid")
            });
            return false
        }
        if (minDate && moment(date).isBefore(minDate)) {
            enqueueError({
                logInfo: EVENT_LOG_DATA.FILTER_DATE_ADD,
                error: new Error(`The date must be after: ${moment(minDate).format(paramDateFormat)}`),
            });
            return false;
        }
        if (maxDate && moment(date).isAfter(maxDate)) {
            enqueueError({
                logInfo: EVENT_LOG_DATA.FILTER_DATE_ADD,
                error: new Error(`The date must be before: ${moment(maxDate).format(paramDateFormat)}`)
            });
            return false;
        }
        return true;
    }

    const updateDate = (date: Date): void => {
        const dateParam = moment(date).local().format(paramDateFormat);
        if (urlParams) setURLParam(dateParam);
        search({id: dateParam, name: id});
    };

    const search = useCallbackWithDebounce((filterOption: FilterOption): void => {
        if (!isValid(moment(filterOption.id).toDate())) return;
        onUpdate(filterOption, filterOption.name);
    });

    return ( 
        <div className={styles.content}>
            { title && <h4 data-testid="date-picker-filter-title" className={styles["filter-title"]}>{title}</h4>}
            <DatePicker
                autoOk={true}
                className={classnames(styles.datepicker, className)}
                open={datePickerOpen}
                placeholder={placeholder}
                value={date}
                onChange={updateDate}
                onClick={() => setDatePickerOpen(true)}
                onOpen={() => setDatePickerOpen(true)}
                onClose={() => setDatePickerOpen(false)}
                format={dateFormat}
                variant="inline"
                disableToolbar={true}
                disabled={disable}
                minDate={minDate}
                maxDate={maxDate}
            />
        </div>
    );
}
