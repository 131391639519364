import React from "react";
import styles from "./Header.module.scss";
import { ReactComponent as IconVacasaLogoHoz } from "../../assets/icons/vacasa-logo-horizontal.svg"
import { isIframe } from "utils/helpers";

export const Header: React.FC = () => {

    // if within iframe we hide the header to save some space
    if (isIframe()) return <></>
    
    return (
        <div className={styles.header}>
            <IconVacasaLogoHoz  data-testid="header-logo"/>
        </div>
    )
}
