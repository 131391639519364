import React from "react";
import styles from "./TicketSearchFiltersSlim.module.scss";
import { MultiSelectFilter } from "components/multi-select-filter/MultiSelectFilter";
import { RootState, useAppDispatch } from "store/store";
import { setReload, setSelectedFilterValues } from "store/ticketSearchSlice";
import type { FilterOption, SearchSelectedFilterValues } from "typing/request";
import { useSelector } from "react-redux";
import { ListFilterEnum, STATUS_MAP } from "appConstants";
import { DatePickerFilter } from "components/date-picker-filter/DatePickerFilter";
import moment from "moment";
import { TextInputFilter } from "components/text-input-filter/TextInputFilter";

interface ITicketSearchFiltersSlimProps {
}

export const TicketSearchFiltersSlim: React.FC<ITicketSearchFiltersSlimProps> = () => {
    const dispatch = useAppDispatch();
    const statusOptions = Array.from(STATUS_MAP, ([key, value]) => ({id: key, name: value}));
    const shouldAddParams = false;
    const selectedFilterValues: SearchSelectedFilterValues = useSelector(
        (state: RootState) => state.ticketSearch.selectedFilterValues
    );
    const updateList = (selectedList: FilterOption|FilterOption[], filterId: string) => {
        const newFilters = {
            ...selectedFilterValues,
            [filterId]: selectedList,
        };
        dispatch(setSelectedFilterValues(newFilters));
        dispatch(setReload());
    };

    const startDate = moment(selectedFilterValues?.creation_date_gte?.id).toDate();
    const endDate = moment(selectedFilterValues?.creation_date_lte?.id).toDate();

    return (
        <div className={styles.content}>
            <div className={styles.item}>
                <DatePickerFilter
                    id={ListFilterEnum.START_DATE}
                    title="Start Date"
                    onUpdate={updateList}
                    className={styles.datepicker}
                    date={startDate}
                    urlParams={shouldAddParams}
                    maxDate={endDate}
                />
            </div>
            <div className={styles.item}>
                <DatePickerFilter
                    id={ListFilterEnum.END_DATE}
                    title="End Date"
                    date={endDate}
                    onUpdate={updateList}
                    className={styles.datepicker}
                    urlParams={shouldAddParams}
                    minDate={startDate}
                />
            </div>
            <div className={styles.item}>
                <MultiSelectFilter
                    title="Status"
                    id={ListFilterEnum.STATUS}
                    options={statusOptions}
                    onUpdate={updateList}
                    selectedValues={selectedFilterValues[ListFilterEnum.STATUS]}
                    minimumCharacters={0}
                    urlParams={shouldAddParams}
                />
            </div>
            <div className={styles.item}>
                <TextInputFilter
                    title="Ticket Name"
                    id={ListFilterEnum.TITLE}
                    onUpdate={updateList}
                    urlParams={shouldAddParams}
                />
            </div>
        </div>
    );
}
