import {ImplicitFlowService} from "./ImplicitFlowService";
import {env} from "core/env";
import {OwnerSummaryAttributes} from "typing/dto";

export class GenAIService extends ImplicitFlowService {
    private static baseUrl = env.REACT_APP_GENAI_SERVICE_URL;
    private static service: GenAIService;

    constructor() {
        super(GenAIService.baseUrl);
    }

    /**
     * Get Singleton Instance
     */
    public static getInstance(): GenAIService {
        if (!GenAIService.service) {
            GenAIService.service = new GenAIService();
        }
        return GenAIService.service;
    }

    /**
     * Get Owner Summary
     */
    public async getOwnerSummaryByUnitId(legacyUnitId: number): Promise<OwnerSummaryAttributes | null> {
        try {
            const axios = await this.axiosInstance();
            const {data: response} = await axios.get(`${this.apiUrl}/owner-summary/lookup/${legacyUnitId}`);
            return response;
        } catch (e) {
            if (!(e instanceof Error)) throw e;
            this.logger.error("getOwnerSymmaryByUnitId", e);
            throw e;
        }
    }
}
