import {useEffect} from "react";

/**
 * This useEffect implementation calculates the height of the React application’s root element
 * and communicates it to the parent window via postMessage. Additionally, it observes changes
 * within the root element to dynamically update the height as the content changes.
 */
const useResizeObserver = (elementId: string, topic?: string) => {
    useEffect(() => {
        const element = document.getElementById(elementId);
        if (!element) return;

        const sendHeightToParent = () => {
            const height = element.offsetHeight ?? 0;
            window.parent.postMessage({type: "resize", action: height, topic}, "*");
        };

        sendHeightToParent();

        const observer = new MutationObserver(sendHeightToParent);
        observer.observe(element, {childList: true, subtree: true});

        return () => observer.disconnect();
    }, [elementId, topic]);
};

export default useResizeObserver;
