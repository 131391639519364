import {Configuration} from 'core/configuration'; // keep me at the top to ensure logger get's tagged before any dependency uses it
import React from 'react';
import ReactDOM from 'react-dom/client';
import Ticketing from './Ticketing';
import DefaultTheme from "@vacasa/react-components-lib/src/themes/default";
import {ImplicitFlow, ThemeProvider} from "@vacasa/react-components-lib";
import { ToastSuccess, ToastError } from 'components/toast/Toast'
import { SnackbarProvider } from 'notistack';
import {Provider} from "react-redux";
import {store} from "store/store";
import './styles/base.scss';
import ConfigProvider from 'ConfigProvider';

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.Fragment>
        <ThemeProvider theme={DefaultTheme}>
            <ImplicitFlow {...Configuration.implicitFlowConfig()} followRedirect={true}>
                <Provider store={store}>
                    <SnackbarProvider
                        Components={{
                            success: ToastSuccess,
                            error: ToastError
                        }}
                        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    />
                    <ConfigProvider>
                        <Ticketing />
                    </ConfigProvider>
                </Provider>
            </ImplicitFlow>
        </ThemeProvider>
  </React.Fragment>
);
