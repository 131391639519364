import React, { useState } from "react";
import styles from "./TicketOwnerSummarySentiment.module.scss";
import { ReactComponent as OpenCircle } from "assets/icons/open-circle.svg";
import { ReactComponent as CloseCircle } from "assets/icons/close-circle-v2.svg";
import { OwnerSummaryTopicMapping } from "appConstants";
import { hostAppUrlBuilders } from "utils/helpers";

interface ITicketOwnerSummarySentimentProps {
    topic: string;
    ticketIds: Array<String>;
    summary: string
}

export const TicketOwnerSummarySentiment: React.FC<ITicketOwnerSummarySentimentProps> = ({
    topic,
    ticketIds,
    summary
}) => {
    
    // internal state
    const [isOpen, setIsOpen] = useState(false);

    // opens ticket in a new tab
    const goToTicket = (ticketId: string) => {
        window.open(hostAppUrlBuilders.admin_legacy(ticketId), "_blank");
    }

    return (
        <div className={styles.topic} data-testid="ticket-owner-summary-sentiment">

            {/* Topic Name */}
            <div className={styles.name} data-testid="ticket-owner-summary-sentiment-name">
                { (Object.keys(OwnerSummaryTopicMapping).includes(topic)) ? OwnerSummaryTopicMapping[topic as keyof typeof OwnerSummaryTopicMapping]: topic }
            </div>

            {/* Ticket Count/List */}
            <div data-testid="ticket-owner-summary-sentiment-tickets">
                { isOpen && ticketIds.map( (ticketId, idx) => {
                    const isLast = idx === ticketIds.length - 1;
                    return (
                        <span
                            key={idx}
                            onClick={() => goToTicket(ticketId as string) }
                            className={styles.link}
                            >#{ticketId}{ isLast ? "": ","} </span>
                    );
                })}
                { !isOpen && (
                    ticketIds.length
                )
                }
            </div>

            {/* Summary */}
            <div data-testid="ticket-owner-summary-sentiment-summary">
                { isOpen ? summary : summary.substring(0,120) + " ..." }
            </div>
            
            <div
                className={styles.toggle}
                onClick={() => setIsOpen(!isOpen)}
                data-testid="ticket-owner-summary-sentiment-toggle"
                >
                { isOpen ? <CloseCircle />: <OpenCircle /> }
            </div>
        </div>
    );
}