import React from "react";
import styles from "./TicketSearchHeader.module.scss";
import { HeaderTitle } from "components/header-title/HeaderTitle";

interface ITicketSearchHeaderProps {
}

export const TicketSearchHeader: React.FC<ITicketSearchHeaderProps> = () => {
    return ( 
        <div data-testid="ticket-search-header" className={styles.content}>
            <HeaderTitle.Standard title="Tickets"/>
        </div>
    );
}
