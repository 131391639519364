// @ts-nocheck
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import {TicketView, TicketEdit, TicketSearch, TicketSearchSlim, TicketOwnerSummary} from "views";
import {Configuration} from "core/configuration";
import {HeaderLayout} from "components/header-layout/HeaderLayout";

interface ITicketingProps {
  RouterComponent?: React.ComponentType;
  routerProps?: Record<string, string[]>;
}
const Ticketing = ({RouterComponent = Router, routerProps = {}} : ITicketingProps): JSX.Element => {
  return (
    <div data-testid={Configuration.innerRootId} id={Configuration.innerRootId} >
      <RouterComponent {...routerProps}>
        <Routes>
          {/** Default Path redirect to Search */}
          <Route path="/" element={<Navigate to="ticket/search" />} />
          
          <Route element={<HeaderLayout />}>
            <Route path="ticket/:ticket_id/edit" element={<TicketEdit />} />
            <Route path="ticket/:ticket_id/view" element={<TicketView />} />
            <Route path="ticket/search" element={<TicketSearch />} />
            <Route path="ticket/owner/summary/:unit_id" element={<TicketOwnerSummary />} />
          </Route>
          
          <Route path="ticket/search/simple" element={<TicketSearchSlim />} />
          {/** Default Path redirect to Search */}
          <Route path="*" element={<Navigate to="/" />} />

        </Routes>
      </RouterComponent>
    </div>
  );
};



export default Ticketing;
