import React from "react";
import { Image } from "@vacasa/react-components-lib";
import classNames from "classnames";
import styles from "./Spinner.module.scss"

interface ISpinnerProps {
  fullscreen?: boolean,
}

export const Spinner: React.FC<ISpinnerProps> = ({ fullscreen = false }) => {
  return (
    <div
      className={
        classNames(
          "spinner", {
          [styles.fullscreen]: fullscreen,
        })
      }
      data-testid="image-spinner"
    >
      <Image.Spinner width={70} />
    </div>
  );
};
