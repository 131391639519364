import React, { useState } from "react";
import styles from "./VisibilityToggle.module.scss";
import { ReactComponent as IconLock } from "assets/icons/lock.svg";
import { ReactComponent as IconPeople } from "assets/icons/people.svg";
import classnames from "classnames";
import { VisibilityStatusEnum } from "appConstants";
import {useSelector} from "react-redux";
import {RootState} from "store/store";

interface IVisibilityToggleProps {
    onUpdate: (visibility: VisibilityStatusEnum) => any;
    className?: string;
    defaultVisibility: VisibilityStatusEnum;
    disabled?: boolean;
}

const visibilityMap = new Map<string, string>([
    [VisibilityStatusEnum.OWNER, "Staff & Owner"],
    [VisibilityStatusEnum.INTERNAL, "Staff Only"]
]);

export const VisibilityToggle: React.FC<IVisibilityToggleProps> = ({className, onUpdate, defaultVisibility, disabled}: IVisibilityToggleProps) => {
    const [visibility, setVisibility] = useState(defaultVisibility);
    const ticketVisibility: VisibilityStatusEnum = useSelector((state: RootState) => (state.ticketEdit?.ticket?.visibility || VisibilityStatusEnum.INTERNAL));

    const handleChange = (newVisibility: VisibilityStatusEnum) => {
        if (disabled) return;
        setVisibility(newVisibility);
        onUpdate(newVisibility);
    };
    // internal state of toggle visibility
    const localIsStaffOnly = visibility === VisibilityStatusEnum.INTERNAL;

    // current ticket visibility
    const ticketIsStaffOnly = ticketVisibility === VisibilityStatusEnum.INTERNAL;

    return (
        <div className={classnames(
                styles["content-toggle"],
                {"disabled": disabled},
                className
            )}
            data-testid="visibility-toggle"
        >
            {/* Selector */}
            <span className={classnames(styles.toggle)}>
                <span
                    data-testid="content-toggle-internal"
                    style={{cursor: disabled ? "not-allowed" : "pointer"}}
                    className={classnames(styles.left, localIsStaffOnly ? styles.active : null)}
                    onClick={() => handleChange(VisibilityStatusEnum.INTERNAL)}
                    >
                    <IconLock />
                </span>
                {!ticketIsStaffOnly && <span
                    style={{cursor: disabled ? "not-allowed" : "pointer"}}
                    data-testid="content-toggle-owner"
                    className={classnames(styles.right, !localIsStaffOnly ? styles.active: null)}
                    onClick={() => handleChange(VisibilityStatusEnum.OWNER)}
                    >
                    <IconPeople />
                </span>}
            </span>
            {/* Info Badge */}
            <VisibilityLabel className={className} defaultVisibility={visibility}/>
        </div>
    );
};

interface IVisibilityLabelProps extends Pick<IVisibilityToggleProps, "className" | "defaultVisibility"> {}
export const VisibilityLabel: React.FC<IVisibilityLabelProps>  = ({className, defaultVisibility = VisibilityStatusEnum.INTERNAL,...rest}: IVisibilityLabelProps) => {
   return (
    <span {...rest} className={classnames(styles.label, className)}>{ visibilityMap.get(defaultVisibility) }</span>
   )
};
