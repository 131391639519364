import { Spinner } from "components/spinner/Spinner";
import { ReactNode, useEffect, useState } from "react";
import { ConnectService } from "services/ConnectService";

interface IConfigProviderProps {
    children: ReactNode;
}

const ConfigProvider: React.FC<IConfigProviderProps> = ({ children }) => {

    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        const initializeConfig = async () => {
          const connectService = ConnectService.getInstance();
          await connectService.setCurrentUser()

          setIsFetching(false);
        };
        initializeConfig();
      }, []);

    return (isFetching ? <Spinner data-testid="spinner" fullscreen /> : children)
}

export default ConfigProvider;
