import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {EVENT_LOG_DATA, HistoryFilterOptionEnum} from "appConstants";
import {TicketHistoryEntity} from "entities/TicketHistory";
import union from "lodash/union";
import uniq from "lodash/uniq";
import {ConnectService} from "services/ConnectService";
import {TicketingService} from "services/TicketingService";
import {OwnerV1Attributes, TicketCommentV1Attributes, TicketHistoryV1Attributes, UserConnectAttributes} from "typing/dto";
import {GetListResponse} from "typing/request";
import {enqueueError, getErrorMessage} from "utils/message";

const {TICKET_HISTORY_GET, TICKET_OWNER_GET, TICKET_HISTORY_LOAD_TAGGED_USERS} = EVENT_LOG_DATA;

// Service Singletons
const ticketingService = TicketingService.getInstance();
const connectService = ConnectService.getInstance();

export interface IFetchHistoryResponse {
    history: TicketHistoryV1Attributes[];
    comments: TicketCommentV1Attributes[];
    users: UserConnectAttributes[];
}

export const fetchHistory = createAsyncThunk(
    "ticketEditHistory/fetchHistory",
    async (ticketId: number, {rejectWithValue}): Promise<IFetchHistoryResponse> => {
        try {
            const [history, comments]: [GetListResponse<TicketHistoryV1Attributes> | null, GetListResponse<TicketCommentV1Attributes> | null] =
                await Promise.all([ticketingService.getHistory({ticket_id: ticketId}), ticketingService.getComments({ticket_id: ticketId})]);

            const userIds = uniq(
                union(
                    ...history.data.map(({attributes}) => [attributes.created_by, new TicketHistoryEntity(attributes).assignee]),
                    ...comments.data.map(({attributes}) => [attributes.assigned_to, attributes.user_id])
                ).filter(Boolean)
            ).join();

            const users = await connectService.getUsers({id: userIds});

            return {
                history: history.data
                    .filter((data) => new TicketHistoryEntity({...data.attributes, id: data.id}).isValid())
                    .map((data) => ({...data.attributes, id: data.id})),
                comments: comments.data.map((data) => ({...data.attributes, id: Number(data.id)})),
                users: users.map((user) => ({...user.attributes, id: Number(user.id)})),
            };
        } catch (error) {
            throw rejectWithValue(getErrorMessage(error));
        }
    }
);

export const fetchTaggedUsers = createAsyncThunk("ticketEditHistory/fetchTaggedUsers", async (usernames: string, {rejectWithValue}) => {
    try {
        const data = await connectService.getUsers({username: {in: usernames}});
        return {
            users: data.map((user) => ({
                ...user.attributes,
                id: Number(user.id),
            })),
        };
    } catch (error) {
        enqueueError({logInfo: TICKET_HISTORY_LOAD_TAGGED_USERS, error: Error(getErrorMessage(error))});
        throw rejectWithValue(getErrorMessage(error));
    }
});

export interface IFetchOwnerResponse {
    owners: OwnerV1Attributes[];
}
export const fetchOwner = createAsyncThunk(
    "ticketEditHistory/fetchOwner",
    async (unitLegacyId: number, {rejectWithValue}): Promise<IFetchOwnerResponse> => {
        try {
            const response = await ticketingService.getOwners({unit_id: unitLegacyId});
            return {
                owners: response.data.map((owner) => ({
                    ...owner.attributes,
                    id: Number(owner.id),
                })),
            };
        } catch (error) {
            throw rejectWithValue(getErrorMessage(error));
        }
    }
);

export type HistoryFilterOption = `${HistoryFilterOptionEnum}`;
export interface ITicketEditHistoryState {
    isLoadingFetch: boolean;
    history: TicketHistoryV1Attributes[];
    filter: HistoryFilterOption[];
    comments: TicketCommentV1Attributes[];
    users: UserConnectAttributes[];
    owners: OwnerV1Attributes[];
}

const ticketEditHistorySlice = createSlice({
    name: "ticketEditHistory",
    initialState: {
        isLoadingFetch: true,
        history: [],
        comments: [],
        filter: [],
        users: [],
        owners: [],
    } as ITicketEditHistoryState,
    reducers: {
        setFilter: (state: ITicketEditHistoryState, {payload}: {payload: HistoryFilterOption[]}) => {
            state.filter = [...payload];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchHistory.pending, (state: ITicketEditHistoryState) => {
                state.isLoadingFetch = true;
                state.history = [];
            })
            .addCase(fetchHistory.fulfilled, (state: ITicketEditHistoryState, {payload}: {payload: IFetchHistoryResponse}) => {
                const {history, comments, users} = payload;
                state.history = [...history];
                state.comments = [...comments];
                state.users = [...users];
                state.isLoadingFetch = false;
            })
            .addCase(fetchHistory.rejected, (state: ITicketEditHistoryState, {payload}: {payload: any}) => {
                enqueueError({logInfo: TICKET_HISTORY_GET, error: Error(payload)});
                state.isLoadingFetch = false;
            })
            .addCase(fetchOwner.pending, (state: ITicketEditHistoryState) => {
                state.isLoadingFetch = true;
            })
            .addCase(fetchOwner.rejected, (state: ITicketEditHistoryState, {payload}: {payload: any}) => {
                enqueueError({logInfo: TICKET_OWNER_GET, error: Error(payload)});
                state.isLoadingFetch = false;
            })
            .addCase(fetchOwner.fulfilled, (state: ITicketEditHistoryState, {payload}: {payload: IFetchOwnerResponse}) => {
                const {owners} = payload;
                state.owners = owners;
                state.isLoadingFetch = false;
            })
            .addCase(fetchTaggedUsers.fulfilled, (state: ITicketEditHistoryState, {payload}: {payload: any}) => {
                state.users = [...state.users, ...payload.users];
            });
    },
});

export const {setFilter} = ticketEditHistorySlice.actions;

export default ticketEditHistorySlice.reducer;
