import React, {useEffect} from "react";
import classNames from 'classnames';
import { TicketSearchHeader } from "components/ticket-search-header/TicketSearchHeader";
import styles from "./TicketSearch.module.scss";
import { TicketSearchFilters } from "components/ticket-search-filters/TicketSearchFilters";
import { TicketSearchTable } from "components/ticket-search-table/TicketSearchTable";
import { closeAlert, fetchUsers, fetchTickets, fetchUnitCodes } from "store/ticketSearchSlice";
import { RootState } from "store/store";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "components/spinner/Spinner";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { AlertMessage, AlertMessageParams } from "components/alert-message/AlertMessage";
import useURLParams from "hooks/useURLParams";
import { filtersToParams, orderByToParams } from "utils/list-params";
import { filterQueryParamsAdapter } from "./TicketSearch.helpers";
import { DEFAULT_FILTER_VALUES, ListFilterEnum } from "appConstants";
import type { GetListResponse, PageQueryParams, SearchFilterOptions, SearchOrderByQueryParams, SearchSelectedFilterValues, TicketingServiceFilter } from "typing/request";
import type { TicketV1Attributes, UnitV2Attributes, UserConnectAttributes } from "typing/dto";
import { ConnectService } from "services/ConnectService";
import { userToFilterOption } from "components/ticket-search-filters/TicketSearchFilter.helpers";

export const TicketSearch: React.FC = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const { searchParams, getParamsByCategory } = useURLParams();
    const tickets = useSelector((state: RootState) => state.ticketSearch.tickets);
    const selectedFilterValues: SearchSelectedFilterValues = useSelector(
        (state: RootState) => state.ticketSearch.selectedFilterValues
    );
    const orderBy: SearchOrderByQueryParams = useSelector((state: RootState) => state.ticketSearch.orderBy)
    const page: PageQueryParams = useSelector((state: RootState) => state.ticketSearch.page);
    const isLoading: boolean = useSelector((state: RootState) => state.ticketSearch.isLoading);
    const reload: boolean = useSelector((state: RootState) => state.ticketSearch.reload);
    const alertMessageParams: AlertMessageParams = useSelector((state: RootState) => state.ticketSearch.alertMessageParams);
    const users: UserConnectAttributes[] = useSelector((state: RootState) => state.ticketSearch.users);
    const units: UnitV2Attributes[] = useSelector((state: RootState) => state.ticketSearch.units);
    const currentConnectUser = ConnectService.getInstance().currentUser;

    // Set the current logged user as default manager filter value
    if (currentConnectUser) DEFAULT_FILTER_VALUES[ListFilterEnum.MANAGER_ID] = [userToFilterOption(currentConnectUser)];

    // Extract filter parameters from the URL query
    const filterQueryParams = getParamsByCategory('filter');
    // Extract pagination parameters from the URL query
    const pageQueryParams = getParamsByCategory('page');
    // Extract sorting parameter from the URL query
    const sortingQueryParams = searchParams.get('sort') || "";

    const fetchData = async (
        filterQueryParams: SearchFilterOptions = {},
        pageQueryParams: PageQueryParams = {},
        sortingQueryParams: string = "",
    ) => {
        const result = await dispatch(fetchTickets({
            page: {...pageQueryParams, ...page},
            filter: {
                ...filtersToParams(DEFAULT_FILTER_VALUES),
                ...(Object.keys(filterQueryParams).length ? filterQueryParamsAdapter(filterQueryParams) : filtersToParams(selectedFilterValues)),
            } as TicketingServiceFilter,
            sort: sortingQueryParams ? sortingQueryParams : orderByToParams(orderBy),
        }));

        const listResponse = result.payload as GetListResponse<TicketV1Attributes>;

        const userIdList = getUsers(listResponse, filterQueryParams as TicketingServiceFilter).join();
        const unitIdList = getUnits(listResponse, filterQueryParams as TicketingServiceFilter).join();

        const fetchPromises = [];
        if (userIdList) fetchPromises.push(dispatch(fetchUsers(userIdList)));
        if (unitIdList) fetchPromises.push(dispatch(fetchUnitCodes(unitIdList)));
        await Promise.all(fetchPromises);
    };

    const getUsers = (
        listResponse: GetListResponse<TicketV1Attributes>,
        filterQueryParams: TicketingServiceFilter
    ): string[] => {
        const {assigned_to, manager_id, follower_id, created_by} = filterQueryParams;
        const assignedToList = listResponse.data.filter(Boolean).map(ticket => String(ticket.attributes.assigned_to));
        const createdByList = listResponse.data.filter(Boolean).map(ticket => String(ticket.attributes.created_by));
        const userArr = [assigned_to, manager_id, follower_id, created_by].filter(Boolean).map(String);
        return [
            ...assignedToList,
            ...createdByList,
            ...userArr
        ];
    };

    const getUnits = (
        listResponse: GetListResponse<TicketV1Attributes>,
        filterQueryParams: TicketingServiceFilter
    ): string[] => {
        const unitIdList = listResponse.data.filter(Boolean).map(ticket => String(ticket.attributes.unit_id));
        if (filterQueryParams?.unit_id) unitIdList.push(String(filterQueryParams.unit_id));
        return unitIdList;
    };

    useEffect(() => {
        // Fetch the data with the parameters obtained from the URL
        fetchData(filterQueryParams, pageQueryParams, sortingQueryParams);
    },[reload]); // eslint-disable-line react-hooks/exhaustive-deps

    const spinnerComponent = <Spinner data-testid="spinner"/>;

    // Display loader until
    if ((!tickets.length && !Object.keys(selectedFilterValues).length) && isLoading) {
        return (
          <div data-testid="spinner" className={styles.spinner} style={{opacity: 1}}>
            {spinnerComponent}
          </div>
        );
    }

    return (
        <div className={classNames(styles.container,"max-width")}>
            {isLoading && <div className={styles.spinner}>
                {spinnerComponent}
            </div>}
            <TicketSearchHeader/>
            <TicketSearchFilters filterQueryParams={filterQueryParams} users={users} units={units}/>
            <AlertMessage params={alertMessageParams} onClose={() => dispatch(closeAlert())}/>
            <TicketSearchTable/>
        </div>
    );
}
