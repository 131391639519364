import {datadogLogs, Logger} from "@datadog/browser-logs";
import axios, {AxiosInstance, AxiosResponse, InternalAxiosRequestConfig} from "axios";
import axiosRetry from "axios-retry";
import jwt_decode from "jwt-decode";
import isNil from "lodash/isNil";
import {CustomRequestConfiguration} from "typing/request";

export class ImplicitFlowService {
    protected logger: Logger;

    // Axios Configurations
    private static axiosRetries = 2;
    private static axiosTimeout = 20000; // 20 seconds
    private currentExecutingRequests: {[key: string]: AbortController} = {};

    constructor(private apiServiceUrl: string) {
        this.logger = datadogLogs.logger;
    }

    get apiUrl(): string {
        return this.apiServiceUrl;
    }

    /**
     * Decodes the Current authenticated User  stored in the Access Token
     */
    static getAuthenticatedUser(): {email: string; name: string; given_name: string; family_name: string} {
        const token = localStorage.getItem("idToken"); // @ts-ignore
        return token && jwt_decode(token);
    }

    /**
     * Axios base config to set as request interceptor.
     * @param config
     * @private
     */
    private axiosRequestBaseConfig(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
        // set authorization headers
        if (config.headers) {
            config.headers["Content-Type"] = "application/vnd.api+json";
            config.headers.Authorization = `Bearer ${localStorage.getItem("accessToken")}`;
            config.headers["Accept-Language"] = "en"; // Used to allow CORS to ConnectAPI
        }

        // set every request timeout
        config.timeout = config.timeout || ImplicitFlowService.axiosTimeout;

        return config;
    }

    /**
     * Request handling in request cancellation management case. Register the url in the current execution requests list
     * @param config
     */
    private axiosRequestCancellationConfig(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
        // abort request management
        if (isNil(config.url)) return config; // early return if url not found
        if (this.currentExecutingRequests[config.url]) {
            // get abort controller of previous request
            const abortController: AbortController = this.currentExecutingRequests[config.url];

            // clean internal object of current requests
            delete this.currentExecutingRequests[config.url];

            // abort previous request
            abortController.abort();
        }

        // save new controller for request
        const source = new AbortController();
        this.currentExecutingRequests[config.url] = source;

        // add signal in the return
        return {
            ...config,
            signal: source.signal,
        };
    }

    /**
     * Response handling in request cancellation management case.
     * @param response
     * @private
     */
    private axiosResponseCancelRequestConfig(response: AxiosResponse): AxiosResponse {
        if (response.config.url && this.currentExecutingRequests[response.config.url]) {
            // clean controller request after response
            delete this.currentExecutingRequests[response.config.url];
        }
        return response;
    }

    /**
     * Error handling in request cancellation management case.
     * @param error
     * @private
     */
    private axiosErrorCancelRequestConfig(error: any): Promise<void | never> {
        // here you check if this is a cancelled request to drop it silently (without error)
        if (axios.isCancel(error)) {
            return new Promise(() => {});
        }
        return Promise.reject(error);
    }

    /**
     * Custom Axios Instance that handles retry/backoff for request failures.
     * Also authenticates every call.
     */
    async axiosInstance({requestCancellation}: CustomRequestConfiguration = {}): Promise<AxiosInstance> {
        const axiosInstance = axios.create();

        if (requestCancellation) {
            // set request cancellation logic
            axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) =>
                this.axiosRequestCancellationConfig(this.axiosRequestBaseConfig(config))
            );
            // set response cancellation logic
            axiosInstance.interceptors.response.use(
                (response) => this.axiosResponseCancelRequestConfig(response),
                (error) => this.axiosErrorCancelRequestConfig(error)
            );
        } else {
            // set base request config
            axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => this.axiosRequestBaseConfig(config));
        }

        // add retry/backoff configuration
        axiosRetry(axiosInstance, {
            retries: ImplicitFlowService.axiosRetries,
            retryDelay: axiosRetry.exponentialDelay,
            shouldResetTimeout: true, // fixes the timeout issue https://github.com/softonic/axios-retry/issues/164#issuecomment-1729651382
            retryCondition: (error): boolean => {
                if ([500, 501, 502].includes(error.response?.status || 0)) return true;
                if (axiosRetry.isNetworkOrIdempotentRequestError(error) || error?.code === "ECONNABORTED") return true; // retry if timeout
                return false;
            },
        });

        return axiosInstance;
    }
}
