import {ListFilterEnum, TicketStatusCodeEnum} from "appConstants";
import moment from "moment";
import type {SearchFilterOptions} from "typing/request";

export class FilterQueryParamsAdapter {
    private filterQueryParams: SearchFilterOptions;

    constructor(filterQueryParams: SearchFilterOptions) {
        this.filterQueryParams = {...filterQueryParams};
    }

    /**
     * Adapts the filter query parameters by applying all relevant handlers.
     *
     * @returns The adapted filter query parameters.
     */
    adapt() {
        this.handleOverdue();
        // Call other handler methods here
        return this.filterQueryParams;
    }

    /**
     * Handles the `overdue` filter by modifying the query parameters accordingly.
     *
     * If the `OVERDUE` parameter is set to `true`, this method:
     * - Sets `STATUS_LTE` to the ID corresponding to `RESOLVED_COMPLETED` status.
     * - Sets `COMPLETION_DATE_LTE` to the current date in "YYYY-MM-DD" format.
     * - Removes the `OVERDUE` parameter from the query parameters.
     */
    handleOverdue() {
        if (this.filterQueryParams[ListFilterEnum.OVERDUE] === "true") {
            this.filterQueryParams[ListFilterEnum.STATUS_LTE] = String(TicketStatusCodeEnum.RESOLVED_COMPLETED - 1);
            this.filterQueryParams[ListFilterEnum.COMPLETION_DATE_LTE] = moment().format("YYYY-MM-DD");
        }
        delete this.filterQueryParams[ListFilterEnum.OVERDUE];
    }

    // Add more handler methods as needed
}

/**
 * Adapts the filterQueryParams that will be send to the ticketing API.
 *
 * e.g. `?overdue=true` is equal to `status_lte=9&completion_date_lte=todayDate` in Ticketing API
 *
 * @param filterQueryParams - The original filter query parameters to adapt.
 * @returns The adapted filter query parameters.
 */
export const filterQueryParamsAdapter = (filterQueryParams: SearchFilterOptions) => {
    const adapter = new FilterQueryParamsAdapter(filterQueryParams);
    return adapter.adapt();
};
