import React, { useState } from "react";
import styles from "./Banner.module.scss" 
import { ReactComponent as IconClose} from "assets/icons/close.svg"
import { Collapse } from "@material-ui/core";

interface IBannerProps {
    header: string,
    text: string,
    icon?: React.ReactNode,
    button?: {text: string, link: string, target?: string},
    close?: boolean,
}

export const Banner: React.FC<IBannerProps> = ({header, text, close=true, button, icon = ""}: IBannerProps) => {
    const [open, setOpen] = useState(true);
    const handleClose = () => setOpen(false);
    const goToLink = (link: string) => window.open(link, button?.target || "_parent");
    return (
        <Collapse in={open}>
            <div data-testid="banner" className={styles.content}>
                <div className={styles['left-side']}>
                    <div>
                        {icon}                 
                    </div>
                    <aside className="banner-text">
                        <h4>{header}</h4>
                        <p>{text}</p>
                    </aside>
                </div>
                <div className={styles['right-side']}>
                    {button &&
                        <button className={styles['link-btn']} onClick={() => goToLink(button.link)}>
                            {button.text}
                        </button>
                    }
                    {close && 
                        <button className={styles['close-btn']} onClick={handleClose}>
                            <IconClose />                   
                        </button>
                    }
                </div>
            </div>
        </Collapse>
    );
}
