// eslint-disable-next-line import/no-anonymous-default-export
export default {
    // Implicit Flow Configuration
    REACT_APP_SPA_URL: "https://ticketing.platform-tools.vacasa.services",
    REACT_APP_SPA_IDP_AUDIENCE: [
        "ticketing.vacasa.io",
        "units.vacasa.io",
        "connect.vacasa.com",
        "api.smart-home.vacasa.io",
        "reservation.vacasa.io",
        "genai.data-science.vacasa.com",
    ].join(" "),

    REACT_APP_SPA_IDP_SCOPES: [
        "maintenance-tickets:read",
        "maintenance-tickets:write",
        "tickets:read",
        "tickets:write",
        "units:read",
        "users:read",
        "smart-home:read",
        "smart-home:write",
        "reservations:read",
        "owner-summary:read",
    ].join(" "),

    // IDP Vars
    REACT_APP_IDP_URL: "https://accounts.vacasa.io",
    REACT_APP_IDP_REDIRECT_URI: "https://ticketing.platform-tools.vacasa.services",
    REACT_APP_IDP_CLIENT_ID: "3pa2xdF5kkPSJgIMUAFCa9rEA8C9ojzvRwY7hLYB",

    // Datadog
    REACT_APP_DD_URL: "datadoghq.com",
    REACT_APP_DATADOG_TOKEN: "pubd8574179531d421033334cd5dbf819ed",

    // Services
    REACT_APP_GENAI_SERVICE_URL: "https://genai.data-science.vacasa.com/api/v1",
    REACT_APP_CONNECT_SERVICE_URL: "https://connect.vacasa.com/v1",
    REACT_APP_TICKETING_SERVICE_URL: "https://ticketing.vacasa.io/v1",
    REACT_APP_UNITS_SERVICE_URL: "https://units.vacasa.io/api/v2",
    REACT_APP_ADMIN_URL: "https://admin.vacasa.com",
    REACT_APP_SMART_HOME_SERVICE_URL: "https://api.smart-home.vacasa.io",
    REACT_APP_RESERVATION_SERVICE_URL: "https://reservation.vacasa.io",

    // Salesforce
    REACT_APP_SALESFORCE_URL: "https://vacasa.lightning.force.com",
};
