import React, { useEffect } from "react"
import styles from "./TicketOwnerSummary.module.scss";
import { RootState } from "store/store";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useSelector, useDispatch} from "react-redux";
import { useParams } from "react-router-dom";
import { ReactComponent as IconPencilAI } from "assets/icons/pencil-ai.svg";
import { TicketOwnerSummarySentiment } from "components/ticket-owner-summary-sentiment/TicketOwnerSummarySentiment";
import { fetchSummary } from "store/ticketOwnerSummarySlice";
import Skeleton from '@mui/material/Skeleton';
import useResizeObserver from "hooks/useResizeObserver";
import { Configuration } from "core/configuration";

export const TicketOwnerSummary: React.FC = () => {
    useResizeObserver(Configuration.innerRootId);

    const {unit_id} = useParams<{unit_id: string}>();
    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const isLoading = useSelector((state: RootState) => state.ticketOwnerSummary.isLoadingFetch);
    const summary = useSelector((state: RootState) => state.ticketOwnerSummary.summary);
    const error = useSelector((state: RootState) => state.ticketOwnerSummary.error);
    

    useEffect(() => {
        dispatch(fetchSummary(Number(unit_id)));
    },[unit_id, dispatch]);

    // Error Managemenet
    if( error ) return <div className={styles.container} data-testid="ticket-owner-summary-error">
        {error}
    </div>
    

    // Early return when loading ....
    if( isLoading || !summary ) return <div className={styles.container} data-testid="ticket-owner-summary-loader">
        <Skeleton width={100}/> 
        <div className={styles.highlevel}>    
            <Skeleton width={100}/> 
            <Skeleton/> 
            <Skeleton/> 
            <Skeleton/> 
        </div><br/><br/>
        <Skeleton width={100}/><br/>
        <div className={styles.categorical}>
            <div className={styles.header}>
                <Skeleton/>
            </div>
            <div className={styles.topics}>
                <br/>
                <Skeleton height={30}/>
                <Skeleton height={30}/>
                <Skeleton height={30}/>
                <br/>
            </div>
        </div>
    </div>
    
    // State Ready Component
    return <div className={styles.container} data-testid="ticket-owner-summary">
        
        {/* High Level */}
        <h2>High Level</h2>
        <hr/>

        <div className={styles.highlevel}>
            <h3>AI Summary Of All Tickets</h3>
            
            <div className={styles.summary} data-testid="ticket-owner-summary-overall">
                {summary.overall_summary}
            </div>
            
            <div className={styles.about}>
                <IconPencilAI />
                <label>
                    AI-generated using ticket descriptions, details, and owner-facing comments for this property.
                </label>
            </div>
        </div>

        {/* Categorical Sentinments */}
        <h2>Categorical Sentiments</h2>
        <hr/>

        <div className={styles.categorical}>
            <div className={styles.header}>
                <div>topic</div>
                <div>ticket</div>
                <div>summary</div>
            </div>
            <div className={styles.topics} data-testid="ticket-owner-summary-sentiments">
                { summary.topics.map( (topic, idx) => (
                    <TicketOwnerSummarySentiment
                        key={idx}
                        topic={topic.topic}
                        ticketIds={topic.ticket_ids}
                        summary={topic.topic_summary}
                    />
                ))} 
            </div>
        </div>
    </div>
}
